// TODO: Refactor to not use `styled`
"use client";

import {
  ComponentType,
  KeyboardEventHandler,
  MouseEventHandler,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef
} from "react";
import { TableBody, TableCell, TableRow, TableRowProps } from "@mui/material";
import { styled } from "@mui/material/styles";

import { DataTableBaseRow, TableColumn } from "./types";

import { useIsMobile } from "common/util/hooks/useIsMobile";

export interface DataTableBodyProps<T extends DataTableBaseRow> {
  selectedRowId?: string;
  data: T[];
  columns: TableColumn<T>[];
  onRowClick?: (id: string, row: T) => void;
  rowHover?: boolean;
  TableRowComponent?: ComponentType<TableRowProps>;
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  scrollMarginTop: "57px",
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0
  },
  "& td": {
    transition: "background-color 300ms ease-in-out"
  },
  "&.selected td": {
    backgroundColor: theme.palette.grey[200],
    fontWeight: 700
  }
}));

export const DataTableBody = <T extends DataTableBaseRow>({
  selectedRowId,
  data,
  columns,
  onRowClick,
  rowHover
}: PropsWithChildren<DataTableBodyProps<T>>) => {
  const isMobile = useIsMobile();
  const rowRef = useRef<HTMLTableRowElement>(null);
  const clickExecution = useCallback(
    (id: string | null) => {
      const rowData = data.find((d) => d.id === id) ?? data[Number(id)] ?? ({} as T);
      if (id && onRowClick) onRowClick(id, rowData);
    },
    [data, onRowClick]
  );

  const handleClick = useCallback<MouseEventHandler<HTMLTableRowElement>>(
    (e) => {
      const id = e.currentTarget?.getAttribute("data-row-id");
      clickExecution(id);
    },
    [clickExecution]
  );

  const handleKeyboardDown = useCallback<KeyboardEventHandler<HTMLTableRowElement>>(
    (e) => {
      if (e.key !== "Enter") return;
      const id = e.currentTarget?.getAttribute("data-row-id");
      clickExecution(id);
    },
    [clickExecution]
  );

  useEffect(() => {
    const element = rowRef.current;
    if (element && !isMobile) {
      element.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
    }
  }, [selectedRowId, isMobile]);

  const rows = useMemo(() => {
    return data.map((item, i) => (
      <StyledTableRow
        key={item.id || i}
        onClick={onRowClick ? handleClick : undefined}
        onKeyDown={onRowClick ? handleKeyboardDown : undefined}
        role={onRowClick ? "button" : "row"}
        tabIndex={0}
        data-testid={item.id}
        data-row-id={item.id}
        className={item.id === selectedRowId ? "selected" : undefined}
        ref={item.id === selectedRowId ? rowRef : undefined}
        hover={rowHover}
        sx={{
          cursor: onRowClick !== undefined ? "pointer" : undefined,
          backgroundColor: item.backgroundColor,
          ...(rowHover
            ? {
                "&:active": {
                  backgroundColor: "component.lightBackground"
                },
                "&:focus": {
                  backgroundColor: "component.lightBackground"
                },
                "&:hover": {
                  backgroundColor: "component.lightBackground"
                }
              }
            : {})
        }}
      >
        {columns
          .filter((h) => !!h.render)
          .map(({ id, render, align, width, verticalAlign, p }) => (
            <TableCell
              key={id}
              align={align}
              sx={{ width, verticalAlign, p }}
              role={onRowClick ? undefined : "cell"}
            >
              {render?.(item)}
            </TableCell>
          ))}
      </StyledTableRow>
    ));
  }, [data, onRowClick, handleClick, handleKeyboardDown, selectedRowId, rowHover, columns]);

  return <TableBody>{rows}</TableBody>;
};
